<template>
  <v-dialog data-app @input="onToggle()" v-model="opened" :retain-focus="false">
    <v-card style="margin: 0">
      <v-icon class="btn-close-modal" @click="opened=false;onToggle();" size="36">mdi-close</v-icon>
      <v-card-title>
        {{ promo_id ? 'Редактирование промокода' : 'Создание промокода' }}
      </v-card-title>
      <v-card-text>
        <v-row v-for="(prop, propName) in promo" :key="propName" v-if="!($store.state.user.city.id==1? ['user_created', 'n_id'] : ['user_created', 'n_id', 'all_cities']).includes(propName)">
          <v-col cols="4">
            <v-subheader>{{ promoTitles[propName] }}</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-checkbox
                v-if="['window', 'standart', 'general', 'remont', 'first_order', 'one_time', 'all_cities'].includes(propName)"
                v-model="promo[propName]"
                true-value="1"
                false-value="0"
                label="да"></v-checkbox>
            <v-menu
                v-else-if="['datefrom', 'dateto'].includes(propName)"
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                top
                nudge-top="35"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    :value="$root.dateToRus(promo[propName])"
                    readonly
                    style="width: 150px"
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="promo[propName]"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  locale="ru"
              />
            </v-menu>
            <v-text-field
                v-model="promo[propName]"
                v-else
                outlined></v-text-field>

          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="updatePromo()" :loading="loading_save_promo">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Promo",
  data() {
    return {
      opened: false,
      promo_id: null,
      promo: {
        code: '',
        title: '',
        discount: '',
        window: '',
        standart: '',
        general: '',
        remont: '',
        datefrom: '',
        dateto: '',
        first_order: '',
        one_time: '',
        all_cities: '',
        percent: ''
      },
      promoTitles: {
        code: 'Код',
        title: 'Заголовок',
        discount: 'Скидка в руб',
        percent: 'Скидка в процентах',
        window: 'Бесплатные окна',
        standart: 'Стандартная уборка',
        general: 'Генеральная уборка',
        remont: 'Уборка после ремонта',
        datefrom: 'Начало акции',
        dateto: 'Конец акции',
        all_cities: 'Для всех городов',
        first_order: 'Только на первый заказ',
        one_time: 'Каждый клиент может использовать только один раз',
      },
      loading_save_promo: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.getPromo()
    },
    onToggle() {
      this.promo = {
        code: '',
        title: '',
        discount: '',
        window: '0',
        standart: '0',
        general: '0',
        remont: '0',
        datefrom: '',
        dateto: '',
        first_order: '',
        one_time: '',
        all_cities: '',
        percent: ''
      }
    },
    getPromo() {
      if (this.promo_id)
        this.$store.state.server.request(`promo/get/${this.promo_id}`, {}, (data) => {
          this.promo = data.response;
          console.log(data.response)
        })
    },
    updatePromo() {
      this.loading_save_promo = true;
      if (this.promo_id) {
        this.$store.state.server.request(`promo/update/${this.promo_id}`, this.promo, () => {
          this.$eventBus.$emit('promoSaved')
          this.loading_save_promo = false;
          this.$root.notify('Промокод сохранен', 'success')
          this.opened = false
        }, (data) => {
          this.loading_save_promo = false;
          if (data && data.error) this.$root.notify(data.error, 'error')
        })
      }
      if (!this.promo_id) {
        this.$store.state.server.request(`promo/create/`, this.promo, () => {
          this.$eventBus.$emit('promoSaved')
          this.loading_save_promo = false;
          this.$root.notify('Промокод создан', 'success')
          this.opened = false
        }, (data) => {
          this.loading_save_promo = false;
          if (data && data.error) this.$root.notify(data.error, 'error')
        })
      }


    }
  }
}
</script>

<style scoped>

</style>
