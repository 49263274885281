<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Промокоды</v-card-title>
        <v-card-actions style="padding: 0 16px;">
          <v-btn @click="editPromo()">Создать</v-btn>
        </v-card-actions>
        <v-card-text>
          <div style="display: flex;gap: 10px">
            <v-checkbox label="Активные" v-model="active"/>
            <v-text-field
                outlined
                v-model="search"
                placeholder="Поиск"
                label=""
                append-icon="mdi-magnify"
            ></v-text-field>
          </div>
          <v-data-table
              hover
              :items="filter(promos, active)"
              :headers="promoHeader"
              item-key="id"
              :search="search"
              :loading="loading_promo"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Промокодов нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr style="cursor: pointer" @click="editPromo(item.n_id)">
                <td>{{ item.code }}</td>
                <td>{{ item.title }}</td>
                <td>{{
                    +item.discount ? item.discount + ' ' + $store.state.user.city.country.currency :
                        item.percent ? item.percent + '%' : ''
                  }}
                </td>
                <td>{{ $root.dateToRus(item.datefrom) }} - {{ $root.dateToRus(item.dateto) }}</td>
                <td>{{ $root.managerName(item.user_created) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <Promo ref="modalPromo"/>
  </v-app>
</template>

<script>
import Promo from "@/views/promo/Promo";

export default {
  name: "TablePromo",
  components: {
    Promo,
  },
  data() {
    return {
      search: '',
      active: true,
      promos: [],
      today: this.$moment().format('YYYY-MM-DD'),
      promoHeader: [
        {text: 'Код', value: 'code'},
        {text: 'Заголовок', value: 'title'},
        {text: 'Сумма/скидка', value: 'discount'},
        {text: 'Период действия', value: 'date'},
        {text: 'Кто создал', value: 'user_created'},
      ],
      loading_promo: true,
    }
  },
  methods: {
    filter(promos, active) {
      return promos.filter(p => {
        return !active || (!p.dateform || this.today >= p.datefrom) && (!p.dateto || this.today <= p.dateto)
      })
    },
    getPromos() {
      this.$store.state.server.request('promo/get', {}, (data) => {
        this.loading_promo = false;
        console.log('promos', data.response)
        this.promos = data.response;
      }, () => {
        this.loading_promo = false;
      })
    },
    editPromo(promo_id = null) {
      const modal = this.$refs.modalPromo;
      modal.promo_id = promo_id
      modal.open()
    }
  },
  mounted() {
    this.getPromos();
    this.$eventBus.$on('promoSaved', response => {
      this.getPromos();
    });
  },
  destroyed() {
    this.$eventBus.$off('promoSaved');
  }
}
</script>

<style scoped>

</style>
